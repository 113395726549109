<template>
  <div class="profile-container">
    <div class="profile-cover" v-if="user !== null">
      <div class="profile-cover-image">
        <v-btn class="btn" @click="goTo()" v-if="userData.uid !== user.uid">
          <v-icon size="20">mdi-arrow-left</v-icon>
        </v-btn>
      </div>
      <div class="profile-image">
        <div class="p-image" v-if="userData.uid === user.uid">
          <img :src="user.profile" alt="" class="image" />
          <div class="middle">
            <div class="text">Edit</div>
          </div>
          <input
            type="file"
            id="image"
            accept="image/*"
            @change="updateProfilePicture"
            placeholder="Update Profile"
          />
        </div>
        <div class="p-image" v-else>
          <img :src="user.profile" alt="" />
        </div>
      </div>
      <div class="profile-header">
        <div class="heading-div">
          <h3
            class="user-name"
            v-if="userData.uid === user.uid || userData.role === 'team'"
          >
            {{ user.name }}
          </h3>
          <div class="not-verified" v-if="!user.verified">Not Verified</div>
          <v-icon
            class="ml-2 pt-1"
            color="#ff7f3f"
            size="20"
            v-else-if="userData.role !== 'meta'"
            >mdi-check-decagram
          </v-icon>
        </div>
        <p>{{ user.role }}</p>
        <p>
          {{ user.uid }}
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="white"
                style="cursor: pointer"
                size="20"
                @click="copyToClipBoard()"
                v-bind="attrs"
                v-on="on"
                >mdi-open-in-new</v-icon
              >
            </template>
            <span>Click to view and share your public profile</span>
          </v-tooltip>
        </p>
        <div
          class="rating"
          v-if="userData.role === 'team' || userData.role === 'meta'"
        >
          <v-row v-if="user.rating !== undefined" style="width: 20%">
            <v-col lg="1" v-for="n in 5" :key="n">
              <v-icon color="yellow darken-3">{{
                n <= user.rating.skill ? "mdi-star" : "mdi-star-outline"
              }}</v-icon>
            </v-col>
            <v-col lg="5">
              <p style="margin: 5px 0px">
                {{
                  user.rating.skill <= 3
                    ? "( Beginner )"
                    : user.rating.skill == 4
                    ? "( Intermediate )"
                    : "( Professional )"
                }}
              </p>
            </v-col>
          </v-row>
          <!-- <div class="star" v-if="user.rating !== undefined">
            <div class="" v-for="n in user.rating.skill" :key="n">
              <v-icon color="yellow darken-3">mdi-star</v-icon>
            </div>
              <p style="margin: 5px 0px">{{user.rating.skill <= 3 ? '( Beginner )' : user.rating.skill ==  4 ? '( Intermediate )' : '( Professional )'}}</p>
          </div> -->
        </div>
      </div>
    </div>
    <div class="profile-details" v-if="user !== null">
      <div
        class="follow-det mt-10"
        v-if="userData.uid !== user.uid && user.role === 'designer'"
        :style="
          userData.role === 'team'
            ? { width: '30% !important' }
            : { width: '20%' }
        "
      >
        <v-btn
          class="follow"
          v-if="!user.followers.includes(this.userData.uid)"
          @click="followUser()"
          >Follow</v-btn
        >
        <v-btn class="follow" @click="followUser()" v-else>Following</v-btn>
        <v-btn
          class="connect"
          @click="connectUser()"
          :style="
            userData.role === 'team'
              ? { 'margin-left': '2%' }
              : { 'margin-left': 'auto' }
          "
          >Request Connect
        </v-btn>
        <v-btn
          class="connect"
          @click="showSkillRate = true"
          v-if="userData.role === 'team'"
          style="margin-left: 2%"
        >
          Rating</v-btn
        >
      </div>
      <div
        class="profile-tab pl-10"
        v-if="userData.uid === user.uid && user.role === 'designer'"
      >
        <div
          :class="
            selectedIndex === index
              ? 'tab-content-other'
              : 'tab-content-not-selected-other'
          "
          v-for="(tab, index) in tabs"
          :key="index"
          @click="changeTab(index)"
        >
          <h3>{{ tab }}</h3>
          <div class="divider" v-if="selectedIndex === index"></div>
        </div>
      </div>
      <div
        class="profile-tab pl-10"
        v-else-if="
          (userData.uid === user.uid && userData.role === 'meta') ||
          (userData.uid === user.uid && userData.role === 'team')
        "
      >
        <div
          :class="
            selectedIndex === index
              ? 'tab-content-other'
              : 'tab-content-not-selected-other'
          "
          v-for="(tab, index) in ownerTab"
          :key="index"
          @click="changeTab(index)"
        >
          <h3>{{ tab }}</h3>
          <div class="divider" v-if="selectedIndex === index"></div>
        </div>
      </div>
      <div
        class="profile-tab pl-10"
        v-else-if="
          (userData.uid !== user.uid && user.role === 'meta') ||
          (userData.uid !== user.uid && user.role === 'team')
        "
      >
        <div
          :class="
            selectedIndex === index
              ? 'tab-content-other'
              : 'tab-content-not-selected-other'
          "
          v-for="(tab, index) in unknownOwner"
          :key="index"
          @click="changeTab(index)"
        >
          <h3>{{ tab }}</h3>
          <div class="divider" v-if="selectedIndex === index"></div>
        </div>
      </div>
      <div class="user-details pl-10" v-if="selectedIndex === 0">
        <div
          class="user-deets mt-10"
          v-if="userData.uid === user.uid || userData.role === 'team'"
        >
          <div class="user-deet-heading">
            <h3 class="heading border-heading">Basic</h3>
            <v-btn
              icon
              color="white"
              @click="showEditPopup(1)"
              v-if="userData.uid === user.uid"
            >
              <v-icon size="20">mdi-pencil</v-icon>Edit
            </v-btn>
          </div>
          <div class="user-div">
            <v-row class="mt-5 mb-5">
              <v-col lg="3">
                <div class="heading-div mb-0">
                  <v-icon color="#ff7f3f" size="20">mdi-account-outline</v-icon>
                  <p class="text-header ml-2">name</p>
                </div>
                <h3 class="user-info ml-6">{{ user.name }}</h3>
              </v-col>
              <v-col lg="4">
                <div class="heading-div mb-0">
                  <v-icon color="#ff7f3f" size="20">mdi-email-outline</v-icon>
                  <p class="text-header ml-2">email</p>
                </div>
                <h3 class="user-info ml-7">{{ user.email }}</h3>
              </v-col>
              <v-col lg="3">
                <div class="heading-div mb-0">
                  <v-icon color="#ff7f3f" size="20">mdi-phone-outline</v-icon>
                  <p class="text-header ml-2">Phone</p>
                </div>
                <h3 class="user-info ml-7">{{ user.phone }}</h3>
              </v-col>
              <v-col lg="3" v-if="user.role === 'designer'">
                <div class="heading-div mb-0">
                  <v-icon color="#ff7f3f" size="20">mdi-flag-outline</v-icon>
                  <p class="text-header ml-2">country</p>
                </div>
                <h3 class="user-info ml-7">{{ user.country }}</h3>
              </v-col>
              <v-col lg="3" v-else-if="user.role === 'meta'">
                <div class="heading-div mb-0">
                  <v-icon color="#ff7f3f" size="20">mdi-account</v-icon>
                  <p class="text-header ml-2">designation</p>
                </div>
                <h3 class="user-info ml-7">{{ user.designation }}</h3>
              </v-col>
            </v-row>
          </div>
        </div>
        <div
          class="user-deets mt-10"
          v-if="
            (userData.uid === user.uid && user.role === 'meta') ||
            user.role === 'meta' ||
            user.role === 'team'
          "
        >
          <div class="user-deet-heading">
            <h3 class="heading border-heading">Organization</h3>
            <v-btn
              icon
              color="white"
              @click="showEditPopup(2)"
              v-if="userData.uid === user.uid"
            >
              <v-icon size="20">mdi-pencil</v-icon>Edit
            </v-btn>
          </div>
          <div class="user-div">
            <v-row class="mt-5 mb-5">
              <v-col lg="4">
                <div class="heading-div mb-0">
                  <v-icon color="#ff7f3f" size="20">mdi-account-outline</v-icon>
                  <p class="text-header ml-2">organization name</p>
                </div>
                <h3 class="user-info ml-6">{{ user.organizationName }}</h3>
              </v-col>
              <v-col lg="4">
                <div class="heading-div mb-0">
                  <v-icon color="#ff7f3f" size="20">mdi-email-outline</v-icon>
                  <p class="text-header ml-2">organization type</p>
                </div>
                <h3 class="user-info ml-7">{{ user.organizationType }}</h3>
              </v-col>
              <v-col lg="4">
                <div class="heading-div mb-0">
                  <v-icon color="#ff7f3f" size="20">mdi-phone-outline</v-icon>
                  <p class="text-header ml-2">webiste link</p>
                </div>
                <a :href="user.websiteLink" target="_blank">
                  <h3 class="user-info ml-7">{{ user.websiteLink }}</h3>
                </a>
              </v-col>
              <v-col lg="3" v-if="user.role === 'designer'">
                <div class="heading-div mb-0">
                  <v-icon color="#ff7f3f" size="20">mdi-flag-outline</v-icon>
                  <p class="text-header ml-2">country</p>
                </div>
                <h3 class="user-info ml-7">{{ user.country }}</h3>
              </v-col>
            </v-row>
            <v-row class="mt-5 mb-5">
              <v-col lg="10">
                <div class="heading-div mb-0">
                  <v-icon color="#ff7f3f" size="20">mdi-account-outline</v-icon>
                  <p class="text-header ml-2">about organization</p>
                </div>
                <h3 class="user-info ml-6">{{ user.about }}</h3>
              </v-col>
            </v-row>
            <v-row class="mt-5">
              <v-col>
                <div class="heading-div mb-7">
                  <v-icon color="#ff7f3f" size="20">mdi-account-outline</v-icon>
                  <p class="text-header ml-2">project requirement</p>
                </div>
                <v-row class="ml-4 mb-5">
                  <v-col
                    lg="3"
                    v-for="(special, index) in user.projectRequirements"
                    :key="index"
                    class="content-div mt-3"
                  >
                    <h3 class="user-info">{{ special }}</h3>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="user-deets mt-10" v-if="user.role === 'designer'">
          <div class="user-deet-heading">
            <h3 class="heading border-heading">Skills</h3>
            <v-btn
              v-if="userData.uid === user.uid"
              icon
              color="white"
              @click="showEditPopup(2)"
            >
              <v-icon size="20">mdi-pencil</v-icon>Edit
            </v-btn>
          </div>
          <div class="user-div">
            <v-row class="mt-5">
              <v-col>
                <div class="heading-div mb-7">
                  <v-icon color="#ff7f3f" size="20"
                    >mdi-folder-star-outline</v-icon
                  >
                  <p class="text-header ml-2">specialization</p>
                </div>
                <v-row class="ml-4">
                  <v-col
                    lg="3"
                    v-for="(special, index) in user.specialization"
                    :key="index"
                    class="content-div mt-3"
                  >
                    <h3 class="user-info">{{ special }}</h3>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="mt-5 mb-5">
              <v-col>
                <div class="heading-div mb-7">
                  <v-icon color="#ff7f3f" size="20">mdi-toolbox-outline</v-icon>
                  <p class="text-header ml-2">tools used</p>
                </div>
                <v-row class="ml-4">
                  <v-col
                    lg="3"
                    v-for="(tools, index) in user.toolsUsed"
                    :key="index"
                    class="content-div"
                  >
                    <h3 class="user-info">{{ tools }}</h3>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="mt-5 mb-5">
              <v-col>
                <div class="heading-div mb-7">
                  <v-icon color="#ff7f3f" size="20"
                    >mdi-projector-screen-outline</v-icon
                  >
                  <p class="text-header ml-2">Project Interested</p>
                </div>
                <v-row class="ml-4">
                  <v-col
                    lg="3"
                    v-for="(interest, index) in user.projectInterest"
                    :key="index"
                    class="content-div"
                  >
                    <h3 class="user-info">{{ interest }}</h3>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="user-deets mt-10" v-if="user.role === 'designer'">
          <div class="user-deet-heading">
            <h3 class="heading border-heading">About</h3>
            <v-btn
              v-if="userData.uid === user.uid"
              icon
              color="white"
              @click="showEditPopup(3)"
            >
              <v-icon size="20">mdi-pencil</v-icon>Edit
            </v-btn>
          </div>
          <div class="user-div">
            <v-row class="mt-0 mb-5">
              <v-col lg="3">
                <div class="heading-div mb-0">
                  <v-icon color="#ff7f3f" size="20"
                    >mdi-account-check-outline</v-icon
                  >
                  <p class="text-header ml-2">years of experience</p>
                </div>
                <h3 class="user-info ml-6">
                  {{ user.yearsOfExperience }} Years
                </h3>
              </v-col>
              <v-col
                lg="3"
                v-if="userData.uid === user.uid || userData.role === 'team'"
              >
                <div class="heading-div mb-0">
                  <v-icon color="#ff7f3f" size="20"
                    >mdi-card-account-details-outline</v-icon
                  >
                  <p class="text-header ml-2">portfolio link</p>
                </div>
                <a :href="user.portfolio" target="_blank">
                  <h3 class="user-info ml-6">{{ user.portfolio }}</h3>
                </a>
              </v-col>
              <v-col
                lg="4"
                v-if="userData.uid === user.uid || userData.role === 'team'"
              >
                <div class="heading-div mb-0">
                  <v-icon color="#ff7f3f" size="20">mdi-link-variant</v-icon>
                  <p class="text-header ml-2">social link</p>
                </div>
                <a :href="user.workUrl" target="_blank">
                  <h3 class="user-info ml-6">{{ user.workUrl }}</h3>
                </a>
              </v-col>
            </v-row>
          </div>
        </div>
        <div
          class="user-deets mt-10"
          v-if="
            userData.uid !== user.uid &&
            user.role === 'designer' &&
            userProjects.length !== 0
          "
        >
          <div class="user-deet-heading">
            <h3 class="heading border-heading">Uploads</h3>
          </div>
          <v-row style="width: 70%">
            <v-col
              lg="4"
              class="upload-container"
              v-for="(uploadIndex, index) in userProjects"
              :key="index"
            >
              <token-card-new
                :data="uploadIndex"
                style="margin-top: 10px"
              ></token-card-new>
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="user-uploads pl-10" v-if="selectedIndex === 1">
        <v-row
          v-if="
            userData.uid !== user.uid &&
            user.role !== 'meta' &&
            user.role !== 'team'
          "
        >
          <div class="upload-card" v-if="userData.uid === user.uid">
            <h3>Upload Your Assets</h3>
            <p>showcase your work</p>
            <div class="upload-image mt-5">
              <img src="../assets/profile/upload.svg" alt="" width="100" />
            </div>
            <v-btn class="button upload-button" @click="onUploadClick()"
              >Upload</v-btn
            >
          </div>
          <v-col
            lg="4"
            class="upload-container"
            v-for="(uploadIndex, index) in userProjects"
            :key="index"
          >
            <token-card-new
              :data="uploadIndex"
              style="margin-top: 10px"
            ></token-card-new>
          </v-col>
        </v-row>
        <v-row
          v-else-if="
            userData.uid === user.uid &&
            user.role !== 'meta' &&
            user.role !== 'team'
          "
          style="width: 80%"
        >
          <v-col lg="4" v-if="userData.uid === user.uid">
            <div class="upload-card" v-if="userData.uid === user.uid">
              <h3>Upload Your Assets</h3>
              <p>showcase your work</p>
              <div class="upload-image mt-5">
                <img src="../assets/profile/upload.svg" alt="" width="100" />
              </div>
              <v-btn class="button upload-button" @click="onUploadClick()"
                >Upload</v-btn
              >
            </div>
          </v-col>
          <v-col
            lg="4"
            class="upload-container mt-2"
            v-for="(uploadIndex, index) in userProjects"
            :key="index"
          >
            <token-card-new
              :data="uploadIndex"
              style="margin-top: 10px"
            ></token-card-new>
          </v-col>
        </v-row>
        <div
          class="user-projects"
          v-else-if="user.role === 'meta' || user.role === 'team'"
        >
          <div class="no-project mt-10" v-if="requests.length === 0">
            <img src="../assets/profile/no-request.svg" width="150" />
            <h3>No Projects Yet!</h3>
            <v-btn
              class="create-project"
              @click="createProject()"
              v-if="user.role === 'meta' && user.role === userData.uid"
              >Create Project</v-btn
            >
          </div>
          <v-row v-if="userData.uid === user.uid">
            <v-col lg="6"></v-col>
            <v-col lg="3" class="ml-10">
              <v-btn
                class="create-project"
                v-if="requests.length > 0"
                @click="createProject()"
                >Create Project</v-btn
              >
            </v-col>
          </v-row>
          <div
            class="request-card"
            v-for="(request, index) in requests"
            :key="index"
          >
            <div class="request-image">
              <img :src="request.uploadedImages[0]" width="100%" />
            </div>
            <div class="request-content">
              <h3>{{ request.name }}</h3>
              <p>{{ request.description }}</p>
              <div class="request-asset-nature">
                <p>Asset Nature:</p>
                <v-row style="width: 90%">
                  <v-col
                    md="3"
                    lg="4"
                    v-for="(nature, index) in request.selectedAssetNature"
                    :key="index"
                    :style="
                      index < 3 ? { display: 'flex' } : { display: 'none' }
                    "
                  >
                    <div class="asset-nature">
                      <v-icon color="#ff7f3f" size="20">mdi-nature</v-icon>
                      <p>{{ nature }}</p>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <v-row align="center" justify="space-between">
                <v-col lg="4">
                  <div class="request-asset-nature">
                    <p>Asset Type:</p>
                    <div class="asset-nature">
                      <v-icon color="#ff7f3f" size="20">mdi-application</v-icon>
                      <p>{{ request.selectedAssetType }}</p>
                    </div>
                  </div>
                </v-col>
                <v-col lg="4">
                  <v-btn
                    class="button manage"
                    @click="onManageClick(request.request_id)"
                    >Manage Project</v-btn
                  >
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="user-projects pl-10" v-else>
        <div class="no-project mt-10" v-if="requests.length === 0">
          <img src="../assets/profile/no-request.svg" width="150" />
          <h3>No Projects Yet!</h3>
        </div>
        <div
          class="request-card"
          v-for="(request, index) in requests"
          :key="index"
        >
          <div class="request-image">
            <img :src="request.uploadedImages[0]" width="100%" />
          </div>
          <div class="request-content">
            <h3>{{ request.name }}</h3>
            <p>{{ request.description }}</p>
            <div class="request-asset-nature">
              <p>Asset Nature:</p>
              <v-row>
                <v-col
                  md="3"
                  lg="3"
                  v-for="nature in request.selectedAssetNature"
                  :key="nature"
                >
                  <div class="asset-nature">
                    <v-icon color="#ff7f3f" size="20">mdi-nature</v-icon>
                    <p>{{ nature }}</p>
                  </div>
                </v-col>
              </v-row>
            </div>
            <v-row align="center" justify="space-between">
              <v-col lg="4">
                <div class="request-asset-nature">
                  <p>Asset Type:</p>
                  <div class="asset-nature">
                    <v-icon color="#ff7f3f" size="20">mdi-application</v-icon>
                    <p>{{ request.selectedAssetType }}</p>
                  </div>
                </div>
              </v-col>
              <v-col lg="4">
                <v-btn class="button manage">Manage Project</v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
      </div> -->
    </div>
    <status-snackbar :text="snackText" :visible="showSnack"></status-snackbar>
    <update-user-profile-new
      :visible="showEdit"
      :user="user"
      :tab="tab"
      @close="close()"
    ></update-user-profile-new>
    <loader :visible="isLoading" :text="loaderText"></loader>
    <StatusModel
      :visible="showStatus"
      :status="isSuccess"
      :title="statusTitle"
      :message="statusMessage"
    ></StatusModel>
    <SkillRating
      :visible="showSkillRate"
      :user="user"
      @close="close()"
    ></SkillRating>
  </div>
</template>
<script>
import { mapState } from "vuex";
import TokenCardNew from "../components/Common/TokenCardNew.vue";
import { assetObj } from "../services/asset-service";
import { requestObj } from "../services/request-service";
import StatusSnackbar from "../components/Common/StatusSnackbar.vue";
import UpdateUserProfileNew from "../components/Modals/UpdateUserProfileNew.vue";
import { userObj } from "../services/user-service";
import Loader from "../components/Loader/Loader.vue";
import StatusModel from "../components/Modals/StatusModel.vue";
import { mailServiceObj } from "../services/mail-service";
import { notificationObj } from "../services/notification-service";
import {
  ADMIN_USER_ID,
  NOTIFICATION_CONTACT_REQUEST,
  NOTIFICATION_USER_FOLLOWER,
} from "../helpers/constants";
import SkillRating from "../components/Modals/SkillRating.vue";
import { uploadFile } from "../helpers/file-upload";

export default {
  components: {
    TokenCardNew,
    StatusSnackbar,
    UpdateUserProfileNew,
    Loader,
    StatusModel,
    SkillRating,
  },
  name: "Profile",
  computed: {
    ...mapState({
      userData: (state) => state.user,
      isConnected: (state) => state.isConnected,
    }),
  },
  data() {
    return {
      selectedIndex: 0,
      tabs: ["Basic Details", "My Uploads"],
      unknownTab: ["Details", "Uploads"],
      unknownOwner: ["Details", "Projects"],
      ownerTab: ["Basic Details", "My Projects"],
      userProjects: ["Project 1", "Project 2", "Project 3", "Project 4"],
      requests: [],
      snackText: "Your profile url is copied to clipboard",
      showSnack: false,
      showEdit: false,
      tab: 1,
      user: {},
      isLoading: false,
      loaderText: "",
      showStatus: false,
      isSuccess: false,
      statusTitle: false,
      statusMessage: false,
      showSkillRate: false,
    };
  },
  methods: {
    changeTab(index) {
      this.selectedIndex = index;
    },
    onUploadClick() {
      this.$router.push("/upload");
    },
    async getAllAssets() {
      const data = await assetObj.getAllLazyMintingAssetByUser(this.user.uid);
      try {
        if (this.isConnected) {
          const chainData = await assetObj.fetchAllLoggedUserAsset();
          const tokens = data.concat(chainData);
          this.userProjects = tokens;
        } else {
          this.userProjects = data;
        }
      } catch (error) {
        this.userProjects = data;
      }
    },
    async getRequestByUserId() {
      if (
        (this.user.role === "meta" && this.userData.uid === this.user.uid) ||
        (this.user.role === "team" && this.userData.uid === this.user.uid)
      ) {
        const allRequest = await requestObj.findByUserId(this.user.uid);
        this.requests = allRequest;
      } else if (
        (this.user.role === "meta" && this.userData.uid !== this.user.uid) ||
        (this.user.role === "team" && this.userData.uid !== this.user.uid)
      ) {
        const allRequest = await requestObj.findByStatusAndUserId(
          "accepted",
          this.user.uid
        );
        this.requests = allRequest;
      } else {
        const allRequest = await requestObj.findByStatus("accepted");
        this.requests = [];
      }
    },
    async copyToClipBoard() {
      const text = this.user.uid;
      const url = `${window.location.origin}/profile/public/${text}`;
      window.open(url, "_blank");
      // const dummy = document.createElement("textarea");
      // document.body.appendChild(dummy);
      // dummy.value = url;
      // dummy.select();
      // document.execCommand("copy");
      // document.body.removeChild(dummy);
      // this.showSnack = true;
      // setTimeout(() => {
      //   this.showSnack = false;
      // }, 2000);
    },
    close() {
      this.showEdit = false;
      this.showSkillRate = false;
    },
    showEditPopup(index) {
      this.tab = index;
      this.showEdit = true;
    },
    async profileLink() {
      this.$router.push("/profile");
    },
    async logout() {
      this.$router.push("/logout");
    },
    getQueryFromRoute() {
      const query = parseInt(this.$route.query.tab);
      if (isNaN(query)) {
        this.selectedIndex = 0;
      } else {
        this.selectedIndex = query - 1;
      }
    },
    async getProfileInformation() {
      const userId = this.$route.params.userId;
      if (userId !== undefined) {
        const userData = await userObj.getUser(userId);
        this.user = userData.data();
        if (this.user.followers === undefined) {
          this.user.followers = [];
        }
        this.getQueryFromRoute();
        this.getAllAssets();
        this.getRequestByUserId();
      } else {
        this.user = this.userData;
        this.getQueryFromRoute();
        this.getAllAssets();
        this.getRequestByUserId();
      }
    },
    async followUser() {
      if (this.user.followers.includes(this.userData.uid)) {
        this.user.followers.splice(
          this.user.followers.indexOf(this.userData.uid),
          1
        );
      } else {
        this.user.followers.push(this.userData.uid);
        const notificationData = {
          title: `You have a new follower`,
          message: `you have a new follower`,
          meta: {},
        };
        await notificationObj.sendNotification(
          notificationData,
          ADMIN_USER_ID,
          this.user.uid,
          NOTIFICATION_USER_FOLLOWER
        );
      }
      await userObj.updateUserData(this.user);
    },
    onManageClick(requestId) {
      this.$router.push(`/request/${requestId}`);
    },
    async connectUser() {
      this.loaderText = "Sending Request...";
      this.isLoading = true;
      try {
        if (this.user.connectRequest === undefined) {
          this.user.connectRequest = [
            {
              userId: this.userData.uid,
              userDetails: this.userData,
            },
          ];
        } else {
          this.user.connectRequest.push({
            userId: this.userData.uid,
            userDetails: this.userData,
          });
        }
        await userObj.updateUserData(this.user);
        const notificationData = {
          title: `${this.userData.name} requested to connect with ${this.user.name}`,
          message: `new connect request`,
          meta: {},
        };
        await notificationObj.sendNotification(
          notificationData,
          this.userData.uid,
          ADMIN_USER_ID,
          NOTIFICATION_CONTACT_REQUEST
        );
        await mailServiceObj.sendMailToAdminDuringConnectRequest(
          this.user,
          this.userData
        );
        this.isLoading = false;
        this.statusTitle = "Request Sent";
        this.statusMessage =
          "Your request has been sent. The team will get back to you shortly";
        this.isSuccess = true;
        this.showStatus = true;
        setTimeout(() => {
          this.showStatus = false;
        }, 2000);
      } catch (error) {
        this.isLoading = false;
        this.statusTitle = "Request Sending Failed";
        this.statusMessage = error.message;
        this.isSuccess = false;
        this.showStatus = true;
        setTimeout(() => {
          this.showStatus = false;
        }, 2000);
      }
    },
    createProject() {
      this.$router.push("/request/new");
    },
    async updateProfilePicture(event) {
      this.isLoading = true;
      this.loaderText = "Updating profile..";
      const files = event.target.files;
      const element = files[0];
      const url = await uploadFile(element, `profile/${this.user.uid}/`);
      this.user.profile = url;
      await userObj.updateUserData(this.user);
      this.isLoading = false;
    },
    goTo() {
      this.$router.go(-1);
    },
  },
  created() {
    this.getProfileInformation();
  },
};
</script>
<style scoped>
.profile-container {
  width: 100vw;
  height: auto;
  padding-bottom: 10%;
  /* background: url("../assets/dashboard.svg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("../assets/hue.svg");
}

.profile-cover {
  width: 100%;
  height: 40vh;
  position: relative;
}

.profile-cover-image {
  width: 100%;
  height: 70%;
  background: transparent;
  background: url("../assets/profile/cover.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  position: absolute;
  left: 20px;
  bottom: -10%;
  cursor: pointer;
}

.p-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: white;
  border: 5px solid #001f5f;
  cursor: pointer;
  position: relative;
}

.p-image > input {
  position: absolute;
  top: 40%;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer !important;
  opacity: 0;
}

.p-image:hover .image {
  opacity: 0.3;
}

.p-image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.text-edit {
  background-color: #04aa6d;
  color: white;
  font-size: 16px;
  padding: 16px 32px;
}
.profile-header {
  width: 100%;
  height: 20%;
  margin-left: 13%;
  padding: 5px;
  background: transparent;
}

.user-name {
  font-size: 18px;
  font-weight: bold;
  color: white;
  margin: 0px;
}

.profile-header > p {
  font-size: 14px;
  font-weight: 400;
  color: white;
  margin: 0px;
  text-transform: capitalize;
}

.profile-details {
  width: 100%;
  padding-right: 10px;
  /* background: url("../assets/dashboard.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; */
}

.profile-tab {
  width: 62%;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background: var(--primary-color); */
  padding-top: 3%;
  padding-right: 40%;
  padding-bottom: 0%;
}

.tab-content {
  flex: 0.4;
  cursor: pointer;
  border: 2px solid #ff7f3f;
  padding: 5px;
  background: rgba(196, 196, 196, 0.18);
  border-radius: 10px;
}

.tab-content > h3 {
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 56.2%,
    #ff3f7f 98.17%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}

.tab-line {
  width: 70%;
  height: 5px;
  background: var(--border-color);
  border-radius: 5px;
}

.tab-content-not-selected {
  flex: 0.4;
  cursor: pointer;
  padding: 5px;
  background: rgba(196, 196, 196, 0.18);
  border-radius: 10px;
}

.tab-content-not-selected > h3 {
  color: #bdbdbd;
  margin: 0px;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
}

.tab-line-not-selected {
  width: 0%;
  height: 5px;
  background: var(--border-color);
  border-radius: 5px;
}

.user-details,
.user-uploads,
.user-projects {
  width: 90%;
  min-height: 100vh;
}

.user-upload {
  width: 90%;
  height: 100%;
}

.upload-card {
  /* width: 300px;*/
  height: 350px;
  background: rgba(224, 224, 224, 0.14);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 20px;
  margin-top: 20px;
  margin-right: 20px;
}

.upload-image {
  width: 50%;
  height: 50%;
}

.upload-card > h3 {
  font-size: 20px;
  font-weight: 700;
  color: #f2f2f2;
  margin: 0px;
}

.upload-card > p {
  font-size: 14px;
  font-weight: 400;
  color: #f2f2f2;
  margin: 0px;
}

.upload-container {
  width: 60%;
}

.upload-button {
  width: 50%;
  height: 40px;
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 56.2%,
    #ff3f7f 98.17%
  );
  border-radius: 10px;
  font-family: "Bebas Neue", cursive !important;
  font-size: 22px;
  color: white;
  font-weight: 400 !important;
}

.no-project {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.no-project > h3 {
  font-size: 20px;
  font-weight: 700;
  color: #f2f2f2;
  margin: 0px;
}

.request-card {
  width: 70%;
  height: 250px;
  background: rgba(224, 224, 224, 0.14);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
  border: 2px solid var(--border-color);
}

.request-image {
  flex: 0.2;
  height: 100%;
  background: rgba(224, 224, 224, 0.14);
  border: 2px solid var(--border-color);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.request-image > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.request-content {
  flex: 0.79;
  height: 100%;
}

.request-content > h3 {
  font-size: 20px;
  font-weight: 700;
  color: #f2f2f2;
  margin: 0px;
}

.request-content > p {
  font-size: 14px;
  font-weight: 400;
  color: #f2f2f2;
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.request-asset-nature {
  margin-top: 10px;
}

.request-asset-nature > p {
  margin: 0px;
  font-size: 12px;
  font-weight: 400;
  color: var(--border-color);
}

.asset-nature {
  padding: 5px;
  background: rgba(217, 217, 217, 0.1);
  text-align: start;
  border-radius: 5px;
  border: 1px solid var(--border-color);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
}

.asset-nature > p {
  font-size: 12px;
  color: white;
  margin: 0px;
  font-weight: 400;
  margin-left: 10px;
  max-lines: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 100%;
}

.manage {
  margin-top: 30px;
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 56.2%,
    #ff3f7f 98.17%
  );
  border-radius: 10px;
  font-weight: 400 !important;
  font-family: "Bebas Neue", cursive;
  color: white;
}

.user-details {
  width: 100%;
}

.heading {
  font-size: 20px;
  font-weight: 700;
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 26.2%,
    #ff3f7f 98.17%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0px;
  font-family: "Orbitron", sans-serif !important;
}

.user-div {
  width: 70%;
  padding: 10px;
  background: rgba(224, 224, 224, 0.14);
  border-left: 3px solid #ff7f3f;
}

.text-header {
  margin: 5px 0px 0px 0px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
}

.user-info {
  margin: 5px 0px 0px 20px;
  color: white;
  font-weight: 700;
  font-size: 18px;
  white-space: initial;
  word-wrap: break-word;
}

.content-div {
  width: 100%;
  padding: 5px;
  border: 0.5px solid #ff7f3f;
  border-radius: 10px;
  margin-left: 10px;
  margin-top: 10px;
}

.heading-div {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.not-verified {
  background: var(--border-color);
  border-radius: 5px;
  font-size: 10px;
  font-weight: 600;
  color: var(--primary-color);
  padding: 2px;
  margin: 5px 0px 0px 10px;
}

.user-deet-heading {
  width: 68%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.follow-det {
  width: 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 3%;
}

.follow {
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 26.2%,
    #ff3f7f 98.17%
  ) !important;
  font-weight: 400;
  font-size: 16px;
  font-family: "Bebas Neue", cursive;
  cursor: pointer;
  margin-top: 10%;
  color: white;
  letter-spacing: 0.9px !important;
  flex: 0.45;
}

.connect {
  border: 1px solid #ff7f3f;
  font-weight: 400;
  font-size: 16px;
  font-family: "Bebas Neue", cursive;
  cursor: pointer;
  margin-top: 10%;
  color: white;
  letter-spacing: 0.9px !important;
  background: rgba(224, 224, 224, 0.14) !important;
  flex: 0.45;
}

.divider {
  width: 100%;
  height: 5px;
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 56.2%,
    #ff3f7f 98.17%
  );
}

.tab-content-other {
  flex: 0.8;
  cursor: pointer;
  padding: 5px;
  border-radius: 10px;
}

.tab-content-other > h3 {
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 56.2%,
    #ff3f7f 98.17%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0px;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
}

.tab-content-not-selected-other {
  flex: 0.8;
  cursor: pointer;
  padding: 5px;
  border-radius: 10px;
}

.tab-content-not-selected-other > h3 {
  color: #bdbdbd;
  margin: 0px;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
}

.create-project {
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 26.2%,
    #ff3f7f 98.17%
  );
  font-family: "Bebas Neue", cursive;
  text-transform: capitalize !important;
  font-weight: 400 !important;
  letter-spacing: 0.8px !important;
  color: white;
  font-size: 20px;
}

.star {
  display: flex;
  justify-content: flex-start;
}
.btn {
  background: transparent !important;
  border-radius: 10px;
  font-weight: 400 !important;
  text-transform: none;
  font-family: "Bebas Neue", cursive !important;
  min-width: 15%;
  margin-top: 10px;
  text-align: center;
  padding: 12px 10px 10px 10px !important;
  margin-right: 10px;
  color: white;
  font-size: 20px;
  letter-spacing: 0.8px !important;
  cursor: pointer;
  position: absolute;
  left: 2%;
  top: 10%;
  z-index: 9999;
  border: 1px solid #eb5129;
}

@media screen and (max-width: 680px) {
  .p-image {
    width: 50px;
    height: 50px;
  }
  .profile-image {
    left: 5px;
    bottom: -25%;
  }
  .profile-header {
    margin-left: 18%;
  }
  .profile-tab {
    width: 100%;
    padding-right: 20%;
  }
  .pl-10 {
    padding-left: 10px !important;
  }
  .user-div {
    width: 85%;
  }
  .row {
    width: 91% !important;
  }
  .follow-det {
    width: 50% !important;
  }
}
</style>
