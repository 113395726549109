<template>
  <div>
    <v-dialog transition="dialog-bottom-transition" persistent v-model="visible" class="dialog" max-width="700">
      <div class="card-body dialog">
        <div class="card-header">
          <v-row>
            <v-col lg="11">
              <h3 class="ml-5">Edit Profile</h3>
            </v-col>
            <v-col lg="01" class="mt-4">
              <v-icon class="span" @click="close()" size="20">mdi-close</v-icon>
            </v-col>
          </v-row>
        </div>
        <v-stepper v-model="tab" dark elevation="0" v-if="user.role === 'designer'">
          <v-stepper-header>
            <v-stepper-step color="#ff7f3f" :complete="tab > 1" step="1">
              Basic
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step color="#ff7f3f" :complete="tab > 2" step="2">
              Skills
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step color="#ff7f3f" step="3"> About </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <div class="card-content mt-1">
                <v-row>
                  <v-col lg="6">
                    <div class="form-group">
                      <p>Full Name</p>
                      <input v-model="user.name" />
                    </div>
                  </v-col>
                  <v-col lg="6">
                    <div class="form-group">
                      <p>Email Address</p>
                      <input v-model="user.email" readonly />
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col lg="6">
                    <div class="form-group">
                      <p>Phone Number</p>
                      <input v-model="user.phone" />
                    </div>
                  </v-col>
                  <v-col lg="6">
                    <div class="form-group">
                      <p>Country</p>
                      <input v-model="user.country" />
                    </div>
                  </v-col>
                </v-row>
                <v-row class="mt-1 pt-0" justify="space-between" style="width: 100%">
                  <v-col lg="12" style="margin-left: 80%">
                    <v-btn class="next-btn button" @click="nextButton(2)">Next</v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-stepper-content>

            <v-stepper-content step="2">
              <div class="card-content mt-1">
                <v-row>
                  <v-col lg="6">
                    <div class="form-group">
                      <p>Specialization</p>
                      <v-select v-model="user.specialization" :items="specializations" multiple class="mySelect" dark
                        solo background-color="transparent" dense>
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index < 1">
                            <span>{{ item }}</span>
                          </v-chip>
                          <span v-if="index === 1" class="grey--text text-caption">
                            (+{{ user.specialization.length - 1 }} others)
                          </span>
                        </template>
                      </v-select>
                    </div>
                  </v-col>
                  <v-col lg="6">
                    <div class="form-group">
                      <p>Tools Used</p>
                      <v-select v-model="user.toolsUsed" :items="toolsUsed" multiple class="mySelect" dark solo
                        background-color="transparent" dense>
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index < 1">
                            <span>{{ item }}</span>
                          </v-chip>
                          <span v-if="index === 1" class="grey--text text-caption">
                            (+{{ user.toolsUsed.length - 1 }} others)
                          </span>
                        </template>
                      </v-select>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col lg="6">
                    <div class="form-group">
                      <p>Project Interested</p>
                      <v-select v-model="user.projectInterest" :items="projectsInterested" multiple class="mySelect"
                        dark solo background-color="transparent" dense>
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index < 1">
                            <span>{{ item }}</span>
                          </v-chip>
                          <span v-if="index === 1" class="grey--text text-caption">
                            (+{{ user.projectInterest.length - 1 }} others)
                          </span>
                        </template>
                      </v-select>
                    </div>
                  </v-col>
                </v-row>
                <div class="card-action">
                  <v-btn class="prev-btn button" @click="prevButton(1)">Previous</v-btn>
                  <v-btn class="next-btn button" @click="nextButton(3)">Next</v-btn>
                </div>
              </div>
            </v-stepper-content>

            <v-stepper-content step="3">
              <div class="card-content mt-1">
                <v-row>
                  <v-col lg="6">
                    <div class="form-group">
                      <p>Years of Experience</p>
                      <input v-model="user.yearsOfExperience" />
                    </div>
                  </v-col>
                  <v-col lg="6">
                    <div class="form-group">
                      <p>Portfolio Link</p>
                      <input v-model="user.portfolio" />
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col lg="6">
                    <div class="form-group">
                      <p>Social Link</p>
                      <input v-model="user.workUrl" />
                    </div>
                  </v-col>
                </v-row>
                <div class="card-action">
                  <v-btn class="prev-btn button" @click="prevButton(2)">Previous</v-btn>
                  <v-btn class="btn button" @click="updateProfile()">Update</v-btn>
                </div>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
        <v-stepper v-model="tab" dark elevation="0" v-if="user.role === 'meta' || user.role === 'team'">
          <v-stepper-header>
            <v-stepper-step color="#ff7f3f" :complete="tab > 1" step="1">
              Basic
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step color="#ff7f3f" :complete="tab > 2" step="2">
              Organization
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <div class="card-content mt-1">
                <v-row>
                  <v-col lg="6">
                    <div class="form-group">
                      <p>Full Name</p>
                      <input v-model="user.name" />
                    </div>
                  </v-col>
                  <v-col lg="6">
                    <div class="form-group">
                      <p>Email Address</p>
                      <input v-model="user.email" readonly />
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col lg="6">
                    <div class="form-group">
                      <p>Phone Number</p>
                      <input v-model="user.phone" />
                    </div>
                  </v-col>
                  <v-col lg="6">
                    <div class="form-group">
                      <p>Designation</p>
                      <input v-model="user.designation" />
                    </div>
                  </v-col>
                </v-row>
                <v-row class="mt-1 pt-0" justify="space-between" style="width: 100%">
                  <v-col lg="12" style="margin-left: 80%">
                    <v-btn class="next-btn button" @click="nextButton(2)">Next</v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-stepper-content>

            <v-stepper-content step="2">
              <div class="card-content mt-1">
                <v-row>
                  <v-col lg="6">
                    <div class="form-group">
                      <p>Organization Name</p>
                      <input v-model="user.organizationName" />
                    </div>
                  </v-col>
                  <v-col lg="6">
                    <div class="form-group">
                      <p>Website Link</p>
                      <input v-model="user.websiteLink" />
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col lg="6">
                    <div class="form-group">
                      <p>Organization Type</p>
                      <v-select v-model="user.organizationType" :items="organizationType" class="mySelect" dark solo
                        background-color="transparent" dense />
                    </div>
                  </v-col>
                  <v-col lg="6">
                    <div class="form-group">
                      <p>Project Requirements</p>
                      <v-select v-model="user.projectRequirements" :items="projectRequirements" multiple chips
                        class="mySelect" dark solo background-color="transparent" dense>
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index < 1">
                            <span>{{ item }}</span>
                          </v-chip>
                          <span v-if="index === 1" class="grey--text text-caption">
                            (+{{ user.projectRequirements.length - 1 }} others)
                          </span>
                        </template>
                      </v-select>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col lg="6">
                    <div class="form-group">
                      <p>About Organization</p>
                      <input v-model="user.about" />
                    </div>
                  </v-col>
                </v-row>
                <div class="card-action">
                  <v-btn class="prev-btn button" @click="prevButton(1)">Previous</v-btn>
                  <v-btn class="btn button" @click="updateProfile()">Update</v-btn>
                </div>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </div>
    </v-dialog>
    <loader :text="'Updating Profile....'" :visible="isLoading"></loader>
    <status-model :status="isSuccess" :message="statusMessage" :title="statusTitle" :visible="showPopup"></status-model>
  </div>
</template>
<script>
import {
  SPECIALIZATIONS,
  TOOLS_USED,
  PROJECT_INTERESTED,
} from "../../helpers/constants";
import Loader from "../Loader/Loader.vue";
import StatusModel from "../Modals/StatusModel.vue";
import { userObj } from "../../services/user-service";

export default {
  components: { Loader, StatusModel },
  name: "UpdateUserProfile",
  props: ["visible", "user", "tab"],
  data() {
    return {
      specializations: SPECIALIZATIONS,
      toolsUsed: TOOLS_USED,
      projectsInterested: PROJECT_INTERESTED,
      organizationType: ["Metaverse Project",
        "Gaming Guild",
        "Brand/Business",
        "Individual",],
      projectRequirements: [
        "Hire 3D Creators",
        "Metaverse Development",
        "Game Development",
        "AR/VR (Immersive experiences)",
        "Characters (Avatars, etc)",
        "Product Mockups and Prototypes",
        "Digital Twins",
        "3D Logo NFT's",
        "Virtual Showrooms",
        "Virtual Events",
        "3D Environments",
        "In-Game Power-ups",
        "Utilities (Cars, Buildings, Game weapons etc.)",
        "All of the above",
      ],
      isLoading: false,
      isSuccess: false,
      showPopup: false,
      statusTitle: "",
      statusMessage: "",
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async updateProfile() {
      this.isLoading = true;
      try {
        await userObj.updateUserData(this.user);
        this.isLoading = false;
        this.isSuccess = true;
        this.statusTitle = "Success";
        this.statusMessage = "Profile updated successfully";
        this.showPopup = true;
        setTimeout(() => {
          this.showPopup = false;
          this.close();
        }, 3000);
      } catch (error) {
        this.isLoading = false;
        this.isSuccess = false;
        this.statusTitle = "Error";
        this.statusMessage = error.message;
        this.showPopup = true;
        setTimeout(() => {
          this.showPopup = false;
        }, 3000);
      }
    },
    nextButton(index) {
      this.tab = index;
    },
    prevButton(index) {
      this.tab = index;
    },
  },
};
</script>
<style scoped>
.dialog {
  background: var(--empty-color);
  padding: 20px;
  border-radius: 10px;
  background-image: url("../../assets/popup.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.span {
  width: 20px;
  height: 20px;
  font-size: 15px;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.heading {
  margin-left: 0px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 20px;
  font-weight: 600;
  margin: 20px 0px 20px 0px;
  text-decoration: underline;
}

.form-group>p {
  margin-bottom: 0px;
  margin-left: 0px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 400;
}

.form-group>input {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #ff7f3f;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}

.form-group>textarea {
  width: 100%;
  height: 100px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #ff7f3f;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}

h3 {
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 56.2%,
      #ff3f7f 78.17%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 40px;
  font-weight: 500;
  font-family: "Orbitron", sans-serif !important;
}

.mySelect {
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #ff7f3f;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
  height: 65px;
}

.card-action {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.btn {
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 56.2%,
      #ff3f7f 98.17%);
  border-radius: 10px;
  font-family: "Bebas Neue", cursive !important;
  color: white !important;
  font-size: 20px;
  width: 20%;
  font-weight: 400 !important;
}

.next-btn {
  background: transparent;
  border-radius: 10px;
  font-family: "Bebas Neue", cursive !important;
  color: white !important;
  font-size: 20px;
  width: 20%;
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 56.2%,
      #ff3f7f 98.17%);
  font-weight: 400 !important;
}

.prev-btn {
  background: transparent !important;
  border-radius: 10px;
  font-family: "Bebas Neue", cursive !important;
  color: white !important;
  font-size: 20px;
  width: 20%;
  border: 1px solid var(--border-color);
  font-weight: 400 !important;
}

.theme--dark.v-stepper {
  background: transparent !important;
}

.v-stepper__header {
  box-shadow: none !important;
}

.v-select>.v-input__control>.v-input__slot:before {
  border-style: none;
}
</style>
